import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import _ from 'lodash';
import moment from 'moment';
import { MONEY, QUERY, S3 } from '../utils';
import AssetLogo from './partials/asset-logo';
import PortfolioChart from './partials/portfolio-chart';
import SignUpModal from './partials/sign-up-modal';
import { BigNumber, BottomCard, BubbleNumber, Dropdown, H2, Modal, SegmentController, TinyButton } from './partials/ui';
import Mark from './partials/logo-mark';
import logo from './assets/img/logo.svg';
import checkIcon from './assets/img/check-blue.svg';
import dollarIcon from './assets/img/dollar.svg';
import menuIcon from './assets/img/menu.svg';
import plusIcon from './assets/img/plus-circle.svg';
import resetIcon from './assets/img/reset.svg';
import shareIcon from './assets/img/share.svg';
import trashIcon from './assets/img/trash.svg';
import xIcon from './assets/img/x.svg';
import Loader from './partials/ui/loader';
import Disclosures from './partials/disclosures';
import css from './simulator.css';

const parseSimulationData = (data) => {
  const parseFloats = (a) => ({
    ...a,
    costBasis: parseFloat(a.costBasis),
    marketValue: parseFloat(a.marketValue),
    dollarReturn: parseFloat(a.dollarReturn),
    dividendReturn: parseFloat(a.dividendReturn),
    percentReturn: parseFloat(a.percentReturn),
  });

  return data.map((d) => {
    const out = parseFloats(d);
    out.history = out.history.map(parseFloats);
    out.positions = out.positions.map(parseFloats);
    return out;
  });
}

const backgroundColor = (n) => {
  if (_.isString(n)) {
    return n.startsWith('-') ? 'orange' : 'cyan';
  }
  return n < 0 ? 'orange' : 'cyan';
}

const percentFormatter = (n, usePrefix = true) => {
  const decimals = Math.abs(n) < 10 ? 2 : 1;
  return `${n < 0 || !usePrefix ? '' : '+'}${n.toFixed(decimals)}%`;
}

const returnMultiple = (simulation) => {
  return simulation.marketValue / simulation.costBasis;
}

const prettyDuration = (d) => {
  if (d === 'mtd') return 'month';
  if (d === 'ytd') return 'year';

  const y = parseInt(d[0]);
  let unit;
  switch (d[1]) {
    case 'd':
      unit = 'day';
      break;
    case 'w':
      unit = 'week';
      break;
    case 'm':
      unit = 'month';
      break;
    case 'y':
      unit = 'year';
      break;
    default:
      return d;
  }

  return y > 1 ? `${y} ${unit}s` : unit;
}

const tagsLUT = new Map();
const templatesLUT = new Map();

const lookup = (companies, lut) => {
  if (!companies?.length) return;
  const key = companies.map((c) => c.ticker).sort().join(',');
  return lut.get(key);
}

function Simulator({ client }) {
  const history = useHistory();
  const location = useLocation();
  const { code } = useParams();
  const { compare, tickers: tickersQuery, d: durationQuery } = QUERY.toObject(location.search);
  const [error, setError] = useState(null);
  const [query, setQuery] = useState('');
  const [user, setUser] = useState(null);
  const [duration, setDuration] = useState(durationQuery || '5y');
  const [reinvestDividends, setReinvestDividends] = useState(true);
  const [companies, setCompanies] = useState([]);
  const [tags, setTags] = useState(null);
  const [templates, setTemplates] = useState(null);
  const [results, setResults] = useState({
    companies: null,
  });
  const [segment, setSegment] = useState('explore');
  const [simulatorState, setSimulatorState] = useState('Chart');
  const [simulation, setSimulation] = useState(null);
  const [simulationData, setSimulationData] = useState(null);
  const [benchmarkCompany, setBenchmarkCompany] = useState(null);
  const [benchmarkData, setBenchmarkData] = useState(null);
  const [invitation, setInvitation] = useState(null);
  const [invitationError, setInvitationError] = useState(null);
  const [isDisclosuresOpen, setIsDisclosuresOpen] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [isShowingSignUpModal, setIsShowingSignUpModal] = useState(false);
  const [hasSeenSignUpModal, setHasSeenSignUpModal] = useState(Boolean(window.localStorage.getItem('hasSeenSignupModal')));
  const [modalContents, setModalContents] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const reset = () => {
    setBenchmarkCompany(null);
    setBenchmarkData(null);
    setSimulation(null);
    setSimulationData(null);
  }

  useEffect(() => {
    try {
      (async () => {
        setUser(await client.users.getMe());
      })();
    } catch {}
  }, []);

  //dnd state
  const [{ isOver }, drop] = useDrop(() => ({
    accept: 'company',
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
    drop: (company) => {
      setCompanies((companies) => {
        const idx = companies.findIndex(c => c.id === company.id);
        return idx === -1 ? [...companies, company] : companies;
      });
    },
  }));

  useEffect(() => {
    (async () => {
      const [
        tags,
        templates,
      ] = await Promise.all([
        client.tags.get(),
        client.templates.get(),
      ]);

      tags.forEach((t) => {
        const tickers = t.companies.map(c => c.ticker);
        const key = tickers.sort().join(',');
        tagsLUT.set(key, t);
      });

      setTags(tags);

      templates.forEach((t) => {
        if (t.templateCompanies.length > 0) {
          const tickers = t.templateCompanies.map(tc => tc.ticker);
          const key = tickers.sort().join(',');
          templatesLUT.set(key, t);
        }
      });

      setTemplates(templates.filter(t => !t.isHidden && t.templateCompanies.length > 0));
    })();

    return () => {
      tagsLUT.clear();
      templatesLUT.clear();
    }
  }, []);

  useEffect(() => {
    if (code) {
      (async () => {
        //if they provided a code, swap it with a sim and strip it
        const simulation = await client.simulations.getOne({ code });
        history.replace(location.pathname.replace(`/${code}`, ''));
        const companies = await client.companies.getByTickers(simulation.tickers.split(','));
        setCompanies(companies);
      })();
    } else if (tickersQuery) {
      //if they provided tickets, get the companies
      const tickers = tickersQuery.split(',');
      if (tickers.length) {
        (async () => {
          const companies = await client.companies.getByTickers(tickers);
          setCompanies(companies);
        })();
      }
    }
  }, []);

  useEffect(() => {
    (async () => {
      if (!query) {
        setResults({ companies: null });
        return;
      }
  
      const { companies } = await client.search.companies({ query, limit: 8 });
      setResults(results => ({ ...results, companies }));
    })();
  }, [query]);

  useEffect(() => {
    if (!companies?.length) {
      const q = new URLSearchParams(location.search);
      q.delete('tickers');
      history.replace(`${location.pathname}?${q.toString()}`);
      return reset();
    }

    (async () => {
      try {
        setIsLoading(true);
        const params = {
          tickers: companies.map(c => c.ticker),
          reinvestDividends,
          weeklyInvestment: 2000,  
        };

        if (duration.includes('/')) {
          const [m, y] = duration.split('/');
          params.m = m;
          params.y = y;
        }

        const simulation = await client.simulations.get(params);
        setSimulation(simulation);
        setSimulationData(parseSimulationData(await S3.getObject({ bucket: simulation.bucket, path: simulation.path })));
        const q = new URLSearchParams(location.search);
        q.set('tickers', companies.map(c => c.ticker).join(','));
        history.replace(`${location.pathname}?${q.toString()}`);
        setError(null);
      } catch (e) {
        setError(e.message);
        setSimulation(null);
        setSimulationData(null);
        console.error(e);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [companies, reinvestDividends]);

  useEffect(() => {
    setBenchmarkCompany(null);
    setBenchmarkData(null);

    if (!companies || !templates) return;
    
    const template = lookup(companies, templatesLUT);
    if (!template?.benchmarkTicker) return;
    (async () => {
      const params = {
        tickers: [template.benchmarkTicker],
        reinvestDividends,
        weeklyInvestment: 2000,
      };

      setBenchmarkCompany(await client.companies.get(template.benchmarkTicker));
      const simulation = await client.simulations.get(params);
      setBenchmarkData(parseSimulationData(await S3.getObject({ bucket: simulation.bucket, path: simulation.path })));
    })();
  }, [companies, templates]);

  useEffect(() => {
    if (!compare) {
      setBenchmarkCompany(null);
      setBenchmarkData(null);
      return;
    }

    (async () => {
      setBenchmarkCompany(await client.companies.get(compare));

      const params = {
        tickers: [compare],
        reinvestDividends: true,
        weeklyInvestment: 2000,  
      };

      const simulation = await client.simulations.get(params);
      setBenchmarkData(parseSimulationData(await S3.getObject({ bucket: simulation.bucket, path: simulation.path })));
    })();
  }, [compare]);

  const isInCompanies = c => _.findIndex(companies, ['id', c.id]) >= 0;

  const isTagSelected = (t) => {
    if (!companies?.length) return false;
    return lookup(companies, tagsLUT)?.id === t.id;
  }

  const isTemplateSelected = (t) => {
    if (!companies?.length) return false;
    return lookup(companies, templatesLUT)?.id === t.id;
  }

  const clear = () => {
    setIsFocused(false);
    setQuery('');
  }

  const onSelectCompany = (company) => {
    const idx = companies.findIndex(c => c.id === company.id);
    if (idx === -1) {
      const nextCompanies = [...companies, company];
      setCompanies(nextCompanies);
    } else {
      onRemoveCompany(company);
    }
  };

  const onSelectTag = (tag) => {
    if (isTagSelected(tag)) {
      setCompanies([]);
    } else {
      setCompanies(tag.companies);
      clear();
    }
  }

  const onSelectTemplate = (template) => {
    if (isTemplateSelected(template)) {
      setCompanies([]);
    } else {
      setCompanies(template.templateCompanies.map(tc => tc.company));
      clear();
    }
  }

  const onRemoveCompany = (c) => {
    const nextCompanies = companies.filter(company => company.id !== c.id);
    setCompanies(nextCompanies);
    history.replace(`${location.pathname}?tickers=${encodeURIComponent(nextCompanies.map(c => c.ticker).join(','))}`)
  };

  const onSubmitPhoneNumber = async (e) => {
    try {
      e.preventDefault();
      const form = new FormData(e.target);
      const phoneNumber = form.get('phoneNumber').replace(/[\s-()]+/g, '');
      if (phoneNumber.length < 10) {
        throw new Error(`Phone number must be at least 10 digits`);
      }

      setIsSending(true);
      setInvitation(await client.invitations.sendWithCode({
        code: simulation.code,
        phoneNumber,
        simulationId: simulation.id,
      }));
    } catch(e) {
      setInvitationError(e.message);
    } finally {
      setIsSending(false);
    }
  };

  //search state
  const hasCompanies = companies?.length > 0;
  const hasResults = Array.isArray(results.companies) && results.companies.length > 0;
  const noResults = Array.isArray(results.companies) && results.companies.length === 0;

  //template state
  const selectedTemplate = hasCompanies ? templates?.find(isTemplateSelected) : null;

  //simulation state
  const durations = ['5y', '3y', '1y'];
  if (user?.isAdmin) {
    durations.push('ytd', 'mtd');
  }

  const sortedCompanies = companies?.sort((a, b) => {
    return a.ticker > b.ticker ? 1 : -1;
  });
  
  const data = simulationData?.find(s => s.duration === (duration.includes('/') ? 'custom' : duration));
  const benchmark = benchmarkData?.find(s => s.duration === (duration.includes('/') ? 'custom' : duration));
  const hasDividends = data?.dividendReturn > 0;
  const sortedPositions = data?.positions.sort((a, b) => {
    return a.diversity < b.diversity ? 1 : -1;
  });

  const hoverBorderColor = (() => {
    if (isOver) return 'cyan';
    if (!companies?.length) return 'silver';
    return 'white';
  })();

  return <>
      {/* Mobile view */}
      <div className="w-100 bg-white tc dn-ns pb5">
        <div className="w-100 pv3 ph3">
          <div className="w-100 flex flex-row items-center justify-between center" style={{maxWidth: 1100}}>
            <Mark scale={1.2} />
            <div className="f4 bold">Portfolio Simulator</div>
            <div className="bg-light-gray br-pill flex items-center" onClick={() => setIsMenuOpen(true)} style={{paddingLeft: 16, paddingRight: 16, paddingTop: 8, paddingBottom: 8}}>
              <img src={menuIcon} style={{width: 16}} />
            </div>
          </div>
        </div> 

        <div className="relative" style={{paddingLeft: 24, paddingRight: 24}}>
          <div className="relative pt3">
            <input
                type="text"
                onFocus={() => setIsFocused(true)}
                autoComplete="off"
                id="company-search"
                className="ba br2 b--moon-gray w-100 border-box pa3 f5"
                placeholder="Search for assets"
                value={query}
                onChange={e => setQuery(e.target.value)} />
            {!isFocused && hasCompanies && <img className="absolute right-1 pointer" src={resetIcon} style={{top: '50%', height: 18}} onClick={() => setCompanies([])} />}
            {isFocused && <img className="absolute right-1 pointer" src={xIcon} style={{top: '50%', height: 14}} onClick={clear} />}
          </div>
          {isFocused && <div className="absolute w-100 right-0 z-999 min-vh-100" style={{backgroundColor: 'rgba(255,255,255,0.9)', backdropFilter: 'blur(5px)', WebkitBackdropFilter: 'blur(5px)'}}>
            {/* If empty, show tags */}
            {!query && <div className="pb6" style={{paddingLeft: 24, paddingRight: 24}}>
              {tags?.map((t) => <div key={t.id} className="w-100 mt4">
                <div className="flex flex-row items-center justify-between">
                  <div className="f4 dark-gray bold tl">{t.name} {t.emoji}</div>
                  <img className="pointer" src={isTagSelected(t) ? checkIcon : plusIcon} onClick={() => onSelectTag(t)} />
                </div>
                <div className="flex flex-row items-center justify-start mt3 overflow-scroll" style={{marginRight: -24}}>
                  {t.companies.map((c) =>
                    <Company company={c} key={c.id} isSelected={isInCompanies(c)} onSelect={() => onSelectCompany(c)} />)}
                </div>
              </div>)}
              {templates?.map((t) => <div key={t.id} className="mt4">
                <div className="flex flex-row items-center justify-between">
                  <div className="f4 dark-gray bold tl">{t.name}</div>
                  <img className="pointer" src={isTemplateSelected(t) ? checkIcon : plusIcon} onClick={() => onSelectTemplate(t)} />
                </div>
                <div className="flex flex-row items-center justify-start mt3 overflow-scroll" style={{marginRight: -24}}>
                  {t.templateCompanies.map(({ company: c }) =>
                    <Company company={c} key={c.id} isSelected={isInCompanies(c)} onSelect={onSelectCompany} />)}
                </div>
              </div>)}
            </div>}
            {/* If searching, always show results */}
            {query && <div className="w-100" style={{paddingLeft: 24, paddingRight: 24}}>
              {!noResults && <>
                {hasResults && <>
                  {_.take(results.companies, 10).map((c) =>
                    <CompanyResult company={c} key={`company-${c.id}`} isInCompanies={isInCompanies(c)} onClick={onSelectCompany} />)}
                </>}
              </>}
              {noResults && <div className="f2 silver tc pv4">We can't find any results for this query.</div>}
            </div>}
          </div>}
          {/* If not searching, and have companies, show the companies */}
          {!isFocused && hasCompanies && <div className="mt3 w-100 flex flex-row items-start justify-start overflow-scroll">
            {sortedCompanies.map((c) =>
              <div key={c.id} className="flex flex-column items-center justify-start mb3" style={{marginRight: 24, flexShrink: 0}}>
                <div>
                  <div style={{flexShrink: 0}}>
                    <AssetLogo company={c} size={40} onClick={() => onRemoveCompany(c)} />
                  </div>
                  <div className="f6 bold near-black lh-copy truncate">{c.ticker}</div>
                </div>
                <img className="pointer mt2" src={trashIcon} style={{width: 20}} onClick={() => onRemoveCompany(c)} />
            </div>)}
          </div>}
        </div>

        <div className="w-100 h-100 flex flex-column mt2">
          <div className="relative pa4 pt0 overflow-scroll">
            {isLoading && <div className="absolute" style={{top: 'calc(50% - 100px)', left: 'calc(50% - 100px)', width: 200}}><Loader /></div>}
            <div className={`flex flex-column ${isLoading ? 'o-50' : ''}`}>
              <div className="bg-near-white ba b--light-gray pa3 f6 lh-copy mv2 br3 tl">
                {Boolean(error) && <>
                  Unable to run simulation, please try again later.
                </>}
                {!error && <>
                  {data && <>If you had invested <span className="">{MONEY.formattedDollars(data.weeklyInvestment/100, { wholeDollar: true })}/week</span> evenly in these stocks for the past {prettyDuration(data.duration)}, you'd have invested <span className="bold">{MONEY.formattedDollars(data.costBasis)}</span> and have <span className="bold">{MONEY.formattedDollars(data.marketValue)}</span> as of market close on {moment.unix(data.endAt).format('MMM Do')}, a return of <span className="bold">{MONEY.formattedRatio(returnMultiple(data), 1)}X.</span></>}
                  {!data && <>Nothing here yet.<br/> Add some assets and to see historical returns.</>}
                </>}
              </div>
              <div className="mb3">
                <SegmentController segments={['Chart', 'Positions']} isDisabled={!data} style="minimal" onChange={setSimulatorState} />
              </div>

              {simulatorState === 'Chart' && <PortfolioPerformance benchmark={benchmark} simulation={data} duration={duration} durations={durations} onChangeDuration={setDuration} />}
              {simulatorState === 'Positions' && <div className="flex flex-column overflow-scroll">
                {sortedPositions?.map((p, idx) =>
                  <div key={p.ticker} className="flex flex-row items-center justify-between mb3">
                    <div className="flex items-center">
                      <div className="mr2">
                        <AssetLogo size={40} ticker={p.ticker} />
                      </div>
                      <div className="truncate">
                        <div className="tl f7 mb1 near-black">{Number((p.diversity * 100).toFixed(1))}% weight</div>
                        <div className="tl f6 bold near-black lh-copy truncate">{p.ticker}</div>
                      </div>
                    </div>
                    <div className="tr">
                      <div className={`${p.percentReturn > 0 ? 'bg-cyan' : 'bg-orange'} pa2 f6 mono br2`}>
                        {p.percentReturn > 0 ? '+' : ''}{(p.percentReturn * 100).toFixed(1)}%
                      </div>
                    </div>
                  </div>
                )}
              </div>}
            </div>
          </div>
        </div>
        {(isMenuOpen || isDisclosuresOpen) && <Modal fullscreen position="start" width="w-90" onDismiss={() => isDisclosuresOpen ? setIsDisclosuresOpen(false) : setIsMenuOpen(false)}>
          {isDisclosuresOpen && <div>
            <div className="tl">
              <H2>Disclosures</H2>
              <Disclosures fontSize="f5" prefix={<>
                This tool is provided for information purposes only, and is not a recommendation or soliciation to buy or sell any security. Prices and calculations are provided on a best effort basis, and may be incorrect for any reason including, but not limited to, bugs, incorrect data from third party data providers, or system delays, errors, or outages. This performance is hypothetical in nature and does not represent the performance of any actual investment.
              </>} />
            </div>
          </div>}
          {!isDisclosuresOpen && <div className="tc pa4">
            <div className="f6 mb3" onClick={() => setIsDisclosuresOpen(true)}>Disclosures</div>
            <a href="https://apps.apple.com/us/app/share-invest/id1543643915" target="_blank">
              <TinyButton outline={true}>Get the App</TinyButton>
            </a>
          </div>}
        </Modal>}

        {!isFocused && <div className="fixed bg-white w-100 bottom-0 left-0" style={{paddingBottom: 20, padding: 24}}>
          <TinyButton outline="true" width="w-50" isDisabled={!simulation} onClick={() => setModalContents('share')}>
            <div className="flex items-center justify-center">
              <img className="mr1" src={shareIcon} style={{width: 14}} />
              Share
            </div>
          </TinyButton>
          <TinyButton outline="true" width="w-50" className="nl1" isDisabled={!simulation} onClick={() => setIsShowingSignUpModal(true)}>
            <div className="flex items-center justify-center">
              <img className="mr1" src={dollarIcon} style={{width: 14}} />
              Invest
            </div>
          </TinyButton>
        </div>}

        {isFocused && <div className="fixed bottom-0 left-0 w-100 bg-white z-999" style={{paddingBottom: 20, padding: 24}}>
          <TinyButton outline={true} width="w-100" onClick={clear}>done</TinyButton>
        </div>}

        {Boolean(modalContents) && <BottomCard dim>
          {modalContents === 'share' && <div>
            <div className="f4 bold">Share</div> 
            <div className="pv3">
              <a href={`https://simulator.tryshare.app/${simulation.code}`}>
                https://simulator.tryshare.app/{simulation.code} 
              </a>
            </div>
            <div className="mt3 mb2">
              <TinyButton outline={true} width="w-100" onClick={() => {
                navigator.clipboard.writeText(`https://simulator.tryshare.app/${simulation.code}`);
                setModalContents(null)
              }}>copy</TinyButton>
            </div>
            <TinyButton outline="none" width="w-100" onClick={() => setModalContents(null)}>cancel</TinyButton>
          </div>}
        </BottomCard>}
      </div>

    {/* Desktop view */}
    <div className="w-100 bg-white tc db-ns dn vh-100">
      <div className="w-100 h-100 center">
            <div className={`tl bb b--light-gray ph4 pv3 flex items-center justify-between`} style={{height: 72, flexShrink: 0}}>
              <div>
                <div className="f3 bold">{String.fromCodePoint(0x1F916)} Simulator</div>
                <div className="f7 gray mt1 flex flex-row items-start justify-start">
                  Built by <a href="https://www.tryshare.app" target="_blank" className="gray underline"><img className="ml1" src={logo} style={{width: 50}} /></a>
                </div>
              </div>
              <div className="flex items-center">
                <TinyButton className="mr1" outline="true" isDisabled={!simulation} onClick={() => setModalContents('share')}>
                  <div className="flex items-center">
                    <img className="mr1" src={shareIcon} style={{width: 14}} />
                    <div style={{marginBottom: 2}}>Share</div>
                  </div>
                </TinyButton>
                <TinyButton outline="true" color={{bg: 'black', text: 'yellow'}} isDisabled={!simulation} onClick={() => setIsShowingSignUpModal(true)}>
                  <div className="flex items-center">
                    <img className="mr1" src={dollarIcon} style={{width: 14}} />
                    <div style={{marginBottom: 2}}>Invest</div>
                  </div>
                </TinyButton>
              </div>
            </div>

        <div className="h-100 flex flex-row" style={{height: 'calc(100% - 72px)'}}>
          <div className="h-100 w-third flex flex-column br b--light-gray" style={{maxWidth: 400, flexShrink: 0}}>
            <div className="pt2 pv3 ph4 overflow-scroll">
              <div className="f5">
                <SegmentController segments={['explore', 'search']} segment={segment} style="minimal" onChange={setSegment} />
              </div>
              {segment === 'search' && <>
                <div className="relative pt2">
                  <input
                      type="text"
                      onFocus={() => setIsFocused(true)}
                      autoComplete="off"
                      id="company-search"
                      className="ba br2 b--moon-gray w-100 border-box pa3 f5"
                      placeholder="Search for assets"
                      value={query}
                      onChange={e => setQuery(e.target.value)} />
                    {isFocused && query && <img className="absolute right-1 pointer" src={xIcon} style={{top: 'calc(50% - 3.5px)', height: 14}} onClick={clear} />}
                </div>

                <div>
                  {/* If empty, show tags */}
                  {!query && tags?.map((t) => <div key={t.id} className="mt3">
                    <div className="flex flex-row items-center justify-between">
                      <div className="f4 dark-gray bold tl">{t.name} {t.emoji}</div>
                      <img className="pointer" src={isTagSelected(t) ? checkIcon : plusIcon} onClick={() => onSelectTag(t)} />
                    </div>
                    <div className="flex flex-row items-center justify-start mt3 overflow-scroll" style={{marginRight: -24}}>
                      {t.companies.map((c) =>
                        <Company company={c} key={c.id} isSelected={isInCompanies(c)} onSelect={() => onSelectCompany(c)} />)}
                    </div>
                  </div>)}
                  {/* If searching, always show results */}
                  {query && <div className="bg-white w-100">
                    {!noResults && <>
                      {hasResults && <div>
                        {_.take(results.companies, 10).map((c) =>
                          <CompanyResult company={c} key={`company-${c.id}`} isInCompanies={isInCompanies(c)} onClick={onSelectCompany} />)}
                      </div>}
                    </>}
                    {noResults && <div className="f2 silver tc pv4">We can't find any results for this query.</div>}
                  </div>}
                </div>
              </>}

              {segment === 'explore' && <>
                <div>
                  {templates?.map((t) => <div key={t.id} className="mt3">
                    <div className="flex flex-row items-center justify-between">
                      <div className="f4 dark-gray bold tl">{t.name}</div>
                      <img className="pointer" src={isTemplateSelected(t) ? checkIcon : plusIcon} onClick={() => onSelectTemplate(t)} />
                    </div>
                    <div className="flex flex-row items-center justify-start mt3 overflow-scroll" style={{marginRight: -24}}>
                      {t.templateCompanies.map(({ company: c }) =>
                        <Company company={c} key={c.id} isSelected={isInCompanies(c)} onSelect={onSelectCompany} />)}
                    </div>
                  </div>)}
                </div>
              </>}
            </div>
          </div>

          <div className={`w-100 h-100 flex flex-column justify-between`} ref={drop} style={{maxWidth: 'calc(100% - 400px)'}}>
            <div>
              <div className="tl f5 bold mt3 ml4">{selectedTemplate?.name || 'Stocks'}</div>
              {benchmarkCompany && <div className="mt1 ml4 tl f6 gray">Benchmark: {benchmarkCompany.displayName}</div>}

              <div id="drop-target" className={`${companies?.length ? '' : 'pulse'} flex flex-row items-center justify-start overflow-scroll mt3 ml4 ba b--dashed br3 pa2 b--${hoverBorderColor} ${isOver || !companies?.length ? 'mr4' : ''}`} style={{minHeight: 100}}>
                {!sortedCompanies.length && <div>
                  <div className={`mid-gray pa3 f6 lh-copy mb4 br3 tl`}>
                    Drag stocks here to run a simulation.
                  </div> 
                </div>}
                {sortedCompanies?.map((c, idx) =>
                  <div key={c.id} className={`relative flex flex-column items-center justify-center pa2 ba br4 b--light-gray mr3`} style={{flexShrink: 0, paddingBottom: 12}} onClick={() => onSelect(c)}>
                    <AssetLogo border={false} company={c} size={40} />
                    <div className="tc bold f6 mt1">{c.ticker}</div>
                    <div className="bg-white br-100 shadow-5 pointer absolute flex items-center justify-center" style={{top: -5, right: -5, padding: 6}} onClick={() => onRemoveCompany(c)} >
                      <img src={xIcon} style={{width: 10}} />
                    </div>
                  </div>)}
              </div>
            </div>
            <div className="relative pa4 pt3 overflow-scroll center w-100">
              {error && <div className="bg-near-white red ba b--light-gray pa3 f6 lh-copy mb2 br4 tl">
                Unable to run simulation, please try again later.
              </div>}
              {data && <div className="flex flex-row items-center justify-center">
                <div className="bg-near-white ba b--light-gray pa3 f6 lh-copy mb2 br4 tl" style={{minHeight: 76}}>
                  If you had invested <span className="">{MONEY.formattedDollars(data.weeklyInvestment/100, { wholeDollar: true })}/week</span> evenly in these stocks for the past {prettyDuration(data.duration)}, you'd have invested <span className="bold">{MONEY.formattedDollars(data.costBasis)}</span> and have <span className="bold">{MONEY.formattedDollars(data.marketValue)}</span> as of market close on {moment.unix(data.endAt).format('MMMM Do')}, a return of <span className="bold">{MONEY.formattedRatio(returnMultiple(data), 1)}X.</span>
                </div>
              </div>}

              {isLoading && <div className="absolute" style={{top: 'calc(50% - 100px)', left: 'calc(50% - 100px)', width: 200}}><Loader /></div>}
              <div className={`flex flex-column ${isLoading ? 'o-50' : ''}`}>
                <div className="w-100 flex items-center justify-end mv2">
                  <Dropdown items={['Chart', 'Positions']} isDisabled={!data} onSelect={setSimulatorState} />
                </div>

                {simulatorState === 'Chart' && <>
                  {Boolean(data) && <div className="flex flex-row flex-wrap mb1">
                    <div className={`flex flex-row mb1-m ${hasDividends ? 'w-100-m w-50' : 'w-100'}`}>
                      <BubbleNumber
                        color={backgroundColor(data.percentReturn)}
                        number={percentFormatter(data.percentReturn * 100)}
                        subtitle={`total return`}
                        width={'w-50 w-100-m'}
                      />
                      <BubbleNumber
                        color={backgroundColor(data.percentReturn)}
                        number={percentFormatter(data.annualizedReturn * 100)}
                        subtitle="annualized return"
                        width={'w-50 w-100-m'}
                      />
                    </div>
                    {data && data.dividendReturn != '0' && <div className="flex flex-row w-100-m w-50">
                      <BubbleNumber
                          color="bright-mint"
                          number={MONEY.formattedDollars(data.dividendReturn)}
                          subtitle="in dividends"
                          width="w-50"
                        />
                        <BubbleNumber
                          color="bright-mint"
                          number={percentFormatter(data.dividendYield * 100, false)}
                          subtitle="dividend yield"
                          width="w-50"
                        />
                    </div>}
                  </div>}

                  <PortfolioPerformance benchmark={benchmark} simulation={data} duration={duration} durations={durations} onChangeDuration={setDuration} />
                </>}
                {simulatorState === 'Positions' && <div className="flex flex-column overflow-scroll">
                  {sortedPositions?.map((p) =>
                    <div key={p.ticker} className="flex flex-row items-center justify-between mb3">
                      <div className="flex items-center">
                        <div className="mr2">
                          <AssetLogo size={40} ticker={p.ticker} />
                        </div>
                        <div className="truncate">
                          <div className="tl f7 mb1 near-black">{Number((p.diversity * 100).toFixed(1))}% weight</div>
                          <div className="tl f6 bold near-black lh-copy truncate">{p.ticker}</div>
                        </div>
                      </div>
                      <div className="tr">
                        <div className={`${p.percentReturn > 0 ? 'bg-cyan' : 'bg-orange'} mono pa2 f6 br2`}>
                          {p.percentReturn > 0 ? '+' : ''}{(p.percentReturn * 100).toFixed(1)}%
                        </div>
                      </div>
                    </div>
                  )}
                </div>}
              </div>
            </div>
          </div>
        </div>
        <div className="absolute bottom-0 right-0 pb1 pr1 f6 silver pointer" onClick={() => setModalContents('disclosures')}>
          Disclosures
        </div>
      </div>
      {!isShowingSignUpModal && modalContents && <Modal>
        {modalContents === 'disclosures' && <div className="pa4">
          <div className="tl">
            <H2>Disclosures</H2>
            <Disclosures prefix={<>
                This tool is provided for information purposes only, and is not a recommendation or soliciation to buy or sell any security. Prices and calculations are provided on a best effort basis, and may be incorrect for any reason including, but not limited to, bugs, incorrect data from third party data providers, or system delays, errors, or outages. This performance is hypothetical in nature and does not represent the performance of any actual investment.
              </>} />
          </div>
          <div><TinyButton outline="true" width="w-100" onClick={() => setModalContents(null)}>Ok</TinyButton></div> 
        </div>}
        {modalContents === 'share' && <div className="pa4 tl" style={{maxWidth: 500}}>
          <div className="f4 bold mb2">Share</div> 
          <div>
            <a href={`https://simulator.tryshare.app/${simulation.code}`}>
              https://simulator.tryshare.app/{simulation.code} 
            </a>
          </div>
          <div className="mt3 mb2">
            <TinyButton outline={true} width="w-100" onClick={() => {
              navigator.clipboard.writeText(`https://simulator.tryshare.app/${simulation.code}`);
              setModalContents(null)
            }}>copy</TinyButton>
          </div>
          <TinyButton outline="none" width="w-100" onClick={() => setModalContents(null)}>cancel</TinyButton>
        </div>}
      </Modal>}
    </div>
    {Boolean(isShowingSignUpModal) && <Modal fullscreen onDismiss={() => setIsShowingSignUpModal(false)}>
      <SignUpModal
        client={client}
        companies={sortedCompanies}
        onDismiss={() => setIsShowingSignUpModal(false)}
        simulation={simulation}
        template={selectedTemplate}
      />
    </Modal>}
  </>;
}

function Company({ company: c, isSelected, onSelect }) {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'company',
    item: c,
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  }));

  return <div key={c.id} className={`flex flex-column items-center justify-center pointer mr3 ${isDragging ? 'o-50' : ''}`} style={{flexShrink: 0}} onClick={() => onSelect(c)}>
    <div className="ba br4 b--light-gray pa2" ref={isSelected ? undefined : drag}>
      <AssetLogo border={false} company={c} size={40} />
      <div className="tc bold f6 mt1 mb2">{c.ticker}</div>
      <img src={isSelected ? checkIcon : plusIcon} />
    </div>
  </div>
}

function PortfolioPerformance({ benchmark, duration, durations, onChangeDuration = _.noop, simulation }) {
  const [mode, setMode] = useState('percent');

  if (!simulation) {
    return <div className="w-100 tc flex flex-column">
    <BigNumber>$-.--</BigNumber>
    <div className={`bg-light-gray ph3 pv2 mt2 f5 mono br2 center`}>-.--% Past {prettyDuration(duration)}</div>
    <PortfolioChart
      chart={_.times(2, () => ({ percentReturn: 0 }))}
      duration={duration}
      durations={durations}
      height={200}
      onChangeDuration={onChangeDuration}
      labelKey="percentReturn"
      valueKey="percentReturn"
    />
  </div>;
  }

  const isUp = simulation.dollarReturn >= 0;
  let bgColor;
  if (simulation.costBasis === '0') {
    bgColor = 'light-gray';
  } else if (isUp) {
    bgColor = 'cyan'
  } else {
    bgColor = 'orange';
  }
  
  const performanceText = (() => {
    const performance = mode === 'percent' ? `${(simulation.percentReturn * 100).toFixed(2)}%` : MONEY.formattedDollars(simulation.dollarReturn);
    return `${isUp ? '+' : ''}${performance}`;
  })();

  return <div className="w-100 tc flex flex-column pointer" onClick={() => setMode(mode === 'percent' ? 'dollars' : 'percent')}>
    <PortfolioChart
      chart={simulation.history}
      compare={benchmark?.history}
      duration={duration}
      durations={durations}
      height={200}
      onChangeDuration={onChangeDuration}
      labelKey={mode === 'percent' ? 'percentReturn' : 'dollarReturn'}
      valueKey="percentReturn"
    />
  </div>;
}

function CompanyResult({ company, isInCompanies, onClick }) {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'company',
    item: company,
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  }));

  const _onClick = (e) => {
    e.stopPropagation();
    onClick(company);
  }

  return <div className={`pv3 flex flex-row items-center justify-between ${isDragging ? 'o-50' : ''}`} ref={isInCompanies ? null : drag}>
    <div className="w-80 flex flex-row items-center justify-start">
      <div className="mr2">
        <AssetLogo company={company} size={40} />
      </div>
      <div className="truncate">
        <div className="tl f7 near-black lh-copy">{company.ticker}</div>
        <div className="tl f5 bold near-black lh-copy truncate">{company.displayName}</div>
      </div>
    </div>
    <TinyButton outline="none" onClick={_onClick}>
      <img src={isInCompanies ? checkIcon : plusIcon} />
    </TinyButton>
  </div>
}

export default (props) => {
  return (
    <DndProvider backend={HTML5Backend}>
      <Simulator {...props} />
    </DndProvider>
  );
}