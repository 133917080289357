import React from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import * as Sentry from '@sentry/react';
import { Integrations } from "@sentry/tracing";
import regeneratorRuntime from 'regenerator-runtime';
import NotFound from './views/not-found';
import Simulator from './views/simulator';

import { API, COOKIES } from './utils';

function App() {
  const client = API.getClient({
    idToken: COOKIES.get('id_token'),
  });

  return <Router>
    <Switch>
      <Route exact path="/:code?">
        <Simulator client={client} />
      </Route>
      <Route path="*">
        <NotFound />
      </Route>
    </Switch>
  </Router>;
}

if (process.env.SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.SENTRY_DSN, 
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0,
  });
}

ReactDOM.render(<App />, root);